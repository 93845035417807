import React from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/layout";
import Image from "../components/utilities/image";
import BackgroundImage from "../components/utilities/background-image";
import SEO from "../components/utilities/seo";

import "../styles/pages/about.css";

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      profile: file(relativePath: { eq: "profile.jpeg" }) {
        ...standardImage
      }
      background: file(relativePath: { eq: "artwork/Breakwater.jpeg" }) {
        ...galleryImage
      }
    }
  `);

  return (
    <Layout>
      <div className='site-background'>
        <BackgroundImage src={data.background} />
        <div className='page-wrapper--limited'>
          <SEO title='About the Artist' />
          <h1 className='main-title'>About the artist</h1>
          <div className='text-container'>
            <div id='profile'>
              <Image src={data.profile} />
            </div>
            <p>
              Dr Chan is a historian of Renaissance art, music and visual culture (with a bit of
              scientific visualization thrown in for good measure). She is also a practising
              painter.
            </p>
            <p>
              Amongst other things, she has worked on: flap anatomical diagrams of hearts; drawings
              of the sense of touch; geometrical elements like ‘twiste lines’ and ‘crooked’ planes;
              a cloudscape that is also a world map that is also a musical instrument; a skeleton on
              a choreographic fencing diagram; a wooden music manuscript that is also a jigsaw
              piece; arabesques that are printed, musical, and gestural; why Thomas Weelkes may or
              may not have urinated on the Dean of Chichester Cathedral; how to spot a musical
              object that features no obvious musical content (see above); the making of musical
              ‘Englishness’.
            </p>
            <p>Also many, many seascapes.</p>
          </div>
          <Link to='/'>Back to the homepage</Link>
        </div>
      </div>
    </Layout>
  );
};

export default AboutPage;
